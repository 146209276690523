import React from 'react';
import logotipo from '../Assets/logo/img01.png';
import { IoMenu } from "react-icons/io5";



const Home = ({ setBack, sand, setSand }) => {
  return (
    <section id='home' className='home'>
      {sand? <div className='modal'>
        <div className='modal-menu'>
          
          <a  href='#home' >HOME</a>
          <a  href='#sobre' >SOBRE</a>
          <a  href='#segmentos' >SEGMENTOS</a>
          <a  href='#bonus' >BÔNUS</a>
          <a  href='#contatos' >CONTATOS</a>


        </div>
      </div> : null}

      <div className='navigation'>
        {/* Logotipo */}
        <img
          src={logotipo}
          alt="Logotipo"
          className='logo-image'
        />
        <button onClick={()=>setSand(!sand)} className='menu-sand'><IoMenu size="30px"/>
        </button>
        <div className='menu'>
          
          <a href='#home' >HOME</a>
          <a href='#sobre' >SOBRE</a>
          <a href='#segmentos' >SEGMENTOS</a>
          <a href='#bonus' >BÔNUS</a>
          <a href='#contatos' >CONTATOS</a>


        </div>

        
      </div>
      <div className='content'>
        <div className='content-text'>
          <h1>Faça seus <span className='text-gold'>Agendamentos e Pedidos</span> <br />
            De Maneira <span className='text-gold'>Simples</span><br /> e
            <span className='text-gold'> Eficiente!</span>
          </h1>
          <hr className='line'></hr>
          <p> Gerencie seus compromissos, organize horários,
            notifique clientes, delegue responsabilidades e
            acompanhe todos os detalhes com facilidade.
            Controle sua agenda e <span className='text-gold'>maximize sua produtividade
            de forma inovadora e integrada diretamente ao Whatsapp do seu negócio.</span></p>
        </div>
        <div className='content-img'>
          <div></div>
        </div>
      </div>
    </section>
  );
};

export default Home;