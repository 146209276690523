import React from 'react';

const Contato = () => {
  return (
    <section id='contatos' className='contatos'>
      <h1>Fale conosco <h7 className='text-gold-seg'>!</h7></h1>
      <div className='content-contatos'>
        <p>Natal - RN</p>
        <p>Telefone:<a href='https://wa.me//5584999933738'><h7 className='text-gold-seg'> (84) 99993-3738 </h7></a> </p>
        <p>E-mail:<h7 className='text-gold-seg'> comercial@agendast.com.br</h7> </p>
        <p>Horário de Atendimento:</p>
        <p> <h7 className='text-gold-seg'>Segunda a Sexta, das 9h às 18h</h7></p>
      </div>

      <br></br>
      <br></br>

      <footer className='cred-footer'>
        <p className='cred'>© 2024 Agendast. Todos os direitos reservados. Desenvolvido por <a href='https://www.developeraction.com.br'> <h7 className='text-gold-seg'>Developer Action Soluções em Tecnologia. </h7> </a> </p>
      </footer>

    </section>

  );
};

export default Contato;