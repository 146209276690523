import { useState, useEffect } from 'react';
import './App.css';
import { IoChevronUp } from "react-icons/io5";


import Home from './Componentes/home';
import Sobre from './Componentes/sobre';
import Segmento from './Componentes/segmento';
import Bonus from './Componentes/bonus';
import Contato from './Componentes/contato';

function App() {

  const [back, setBack] = useState(false);
  const [sand, setSand] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Verifica se o usuário desceu mais de 30px na página
      if(window.scrollY > 30){
        setSand(false); // Esconde Menu
      }
      // Verifica se o usuário desceu mais de 300px na página
      if (window.scrollY > 300) {
        setBack(true); // Mostra o botão
      } else {
        setBack(false); // Esconde o botão
      }
    };

    // Adiciona o event listener de scroll
    window.addEventListener('scroll', handleScroll);

    // Remove o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='main'>

      {back?<a href='#home' onClick={()=>setBack(false)} className='back'><IoChevronUp />
      </a>:null}

      <Home setBack={setBack} sand={sand} setSand={setSand}/>
      <Sobre/>
      <Segmento/>
      <Bonus/>
      <Contato/>
      
    </div>
  );
}

export default App;
