import React from 'react';
import logotipo from '../Assets/logo/img02.png';

const Sobre = () => {
  return (
    <section id='sobre' className='sobre'>
      <div className='content-sobre'>
        <div className='content-sobre-img'>
          <div className='content-sobre-img-blur'>
            <img
              src={logotipo}
              alt="Logotipo"
              style={{ width: '180px', height: 'auto' }}
            />
            {/* <p className='logo2'>AGENDAST</p> */}
          </div>
        </div>
        <div className='content-sobre-txt'>
          <h1>O QUE É TEMPO PRA VOCÊ ?</h1>
          <hr className='line'></hr>
          <p>Acredito que não seja necessario nos responder, mas sabemos que 
            <span className='text-gold'> tempo é essencial no seu negócio</span>, 
            imagine seus pacientes marcando consultas de forma 
            instantânea pelo WhatsApp, sem complicações. Sabemos 
            que o tempo é algo preciso e que precisa estar tudo 
            redondinho quando se trata de agendamentos e pedidos, 
            por isso, focamos em inovação, criando uma plataforma 
            intuitiva que abrange a agendamentos e pedidos, 
            que simplifica a gestão de controle, oferencendo 
            uma confortabilidade em controle. Maximize sua 
            produtividade.<span className='text-gold'> Nosso 
              compromisso é oferecer uma solução que você 
              ganhe tempo, adaptando a todas suas necessidades 
              de forma automatizada</span>, ajudando você a 
              gerenciar sua agenda de clientes com eficiência e
               facilidade.</p>
        </div>
      </div>
    </section>
  );
};

export default Sobre;